import React from 'react';
import BankInfoSection from './components/BankInfoSection';
import MovingGradientHero from './components/MovingGradient';
import MobilePhoneSimulator from './components/MobilePhoneSimulator';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import BankSignupForm from './components/BankingSignupForm';
import Host from './components/Host';
import CardDisplay from './components/CardDisplay';
import ServiceOptions from './components/ServiceOptions';


const App: React.FC = () => {
  const handleCtaClick = () => {
    alert('CTA Clicked!');
  };

  return (
    <div>
      <Navbar />
      <Host />
      <MovingGradientHero />
      <MobilePhoneSimulator />
      <CardDisplay />
      <ServiceOptions />
      <BankInfoSection />
      <BankSignupForm />
      <Footer />

    </div>
  );
};

export default App;
