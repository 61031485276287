import React from 'react';

interface CardOption {
    title: string;
    bonusOffer: string;
    annualFee: string;
    features: string[];
    imageUrl: string;
  }
  

const CardComponent: React.FC<{ card: CardOption }> = ({ card }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-blue-800/20">
      <img className="w-full" src={card.imageUrl} alt="Credit Card Image"/>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{card.title}</div>
        <p className="text-red-500 text-base">{card.bonusOffer}</p>
        <p className="text-gray-700 text-base">{card.annualFee}</p>
        {card.features.map((feature, index) => (
          <p key={index} className="text-gray-600 text-sm">{feature}</p>
        ))}
      </div>
    </div>
  );
};

export default CardComponent;
