import React from 'react';

const BankInfoSection: React.FC = () => {
  return (
    <div className="bg-white py-12 px-6 lg:px-12">
      <div className="max-w-screen-lg mx-auto">
        <h2 className="text-3xl text-gray-800 font-semibold mb-6">Welcome to Greater Gotham Bank</h2>
        <p className="text-gray-600 text-lg mb-4">
          At Greater Gotham Bank, we understand the importance of managing your wealth with great care and expertise.
          Our private banking services are tailored to the unique needs of each client, ensuring a personalized and secure banking experience.
        </p>
        <p className="text-gray-600 text-lg mb-8">
          From bespoke investment solutions to comprehensive wealth management planning, our team is dedicated to providing
          top-tier financial services. Discover the benefits of banking with us and how we prioritize your financial wellbeing and privacy.
        </p>
        <div className="flex flex-wrap justify-center gap-4">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow">
            Learn More
          </button>
          <button className="bg-transparent border-2 border-blue-600 hover:bg-blue-600 hover:text-white text-blue-600 font-bold py-3 px-6 rounded-lg transition-colors">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankInfoSection;
