// src/components/Navbar.tsx
import React, { useState } from 'react';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black shadow-lg">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo or brand name */}
          <a href="#" className="py-4 px-2 text-white font-semibold text-lg">Greater Gotham Bank</a>

          {/* Hamburger Icon */}
          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)}>
              <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          {/* Primary Navbar items */}
          <div className={`${isOpen ? 'flex' : 'hidden'} flex-col md:flex-row md:flex items-center md:space-x-1 absolute md:relative bg-black md:bg-transparent left-0 right-0 z-20 md:z-auto top-20 md:top-0`}>
            <a href="#" className="py-4 px-2 text-white font-semibold hover:text-blue-600 transition duration-300">Home</a>
            <a href="#" className="py-4 px-2 text-white font-semibold hover:text-blue-600 transition duration-300">About</a>
            <a href="#" className="py-4 px-2 text-white font-semibold hover:text-blue-600 transition duration-300">Services</a>
            <a href="#" className="py-4 px-2 text-white font-semibold hover:text-blue-600 transition duration-300">Contact</a>
          </div>

          {/* Secondary Navbar Items, shown only on md and larger screens */}
          <div className="hidden md:flex items-center space-x-3">
            <a href="#" className="py-2 px-2 font-medium text-white rounded hover:bg-blue-600 transition duration-300">Log In</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
