import React from 'react';
import CardComponent from './CardComponent';

interface CardOption {
    title: string;
    bonusOffer: string;
    annualFee: string;
    features: string[];
    imageUrl: string;
  }
  

const cards: CardOption[] = [
  {
    title: "Customized Cash Rewards",
    bonusOffer: "$200 online bonus offer",
    annualFee: "No annual fee.",
    features: ["3% cash back in the category of your choice"],
    imageUrl: "/assets/cc1.png"
  },
  {
    title: "Unlimited Cash Rewards",
    bonusOffer: "$200 online bonus offer",
    annualFee: "No annual fee.",
    features: ["Unlimited 1.5% cash back on all purchases"],
    imageUrl: "/assets/cc2.png"
  },
  {
    title: "Travel Rewards",
    bonusOffer: "$200 online bonus offer",
    annualFee: "No annual fee.",
    features: ["Unlimited 1.5% cash back on all purchases"],
    imageUrl: "/assets/cc3.png"
  },
  {
    title: "Gotham Rewards",
    bonusOffer: "$200 online bonus offer",
    annualFee: "No annual fee.",
    features: ["Unlimited 1.5% cash back on all purchases"],
    imageUrl: "/assets/cc4.png"
  },
  // Add other cards similarly
];

const CardDisplay: React.FC = () => {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold text-center mb-8">Choose the card that works for you</h1>
        <div className="flex flex-wrap justify-center gap-4">
          {cards.map((card, index) => (
            <CardComponent key={index} card={card} />
          ))}
        </div>
      </div>
    );
  };

export default CardDisplay;
