// src/components/MobilePhoneSimulator.tsx
import React from 'react';
import '../index.css';  // Ensure your Tailwind CSS is properly imported

const MobilePhoneSimulator: React.FC = () => {
    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl w-full mx-auto p-8">
                {/* Text and interaction area */}
                <div className="flex-1 mb-6 md:mb-0 md:mr-8">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4">Financial Infrastructure for the Internet</h1>
                    <p className="text-base md:text-xl text-gray-600 mb-6">
                        Millions of companies of all sizes use our services to manage their finances,
                        process payments, and grow their businesses.
                    </p>
                    <div className="flex flex-col sm:flex-row sm:space-x-4">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 sm:mb-0">
                            Start Now
                        </button>
                        <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            Contact Sales
                        </button>
                    </div>
                </div>

                {/* Mobile phone simulation */}
                <div className="flex-1 flex justify-center md:justify-end">
                    <img src="GGBMobile5.png" alt="Greater Gotham Bank App" className="w-64 md:max-w-xs" />
                </div>
            </div>
        </div>
    );
};

export default MobilePhoneSimulator;
