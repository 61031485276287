// src/components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-6xl mx-auto px-4 py-6">
        <div className="sm:flex justify-between items-center">
          <div className="text-center sm:text-left mb-4 sm:mb-0">
            <p className="text-lg font-bold">Greater Gotham Bank</p>
            <p>Empowering your financial freedom</p>
          </div>
          <div className="flex justify-center space-x-4">
            <a href="#" className="hover:text-gray-400 transition-colors">Privacy Policy</a>
            <a href="#" className="hover:text-gray-400 transition-colors">Terms of Use</a>
            <a href="#" className="hover:text-gray-400 transition-colors">Contact Us</a>
          </div>
        </div>
        <div className="text-center sm:text-left mt-4 sm:mt-0">
          <p>&copy; {new Date().getFullYear()} Greater Gotham Bank. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
