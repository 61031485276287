import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Host.css'; // Ensure the CSS path is correct

const Host: React.FC = () => {
  return (
    <div className="relative h-screen flex items-center justify-center">
      <video autoPlay loop muted className="video-background">
        {/* Standard video for larger screens */}
        <source src="/assets/banner.mp4" type="video/mp4" media="screen" />
      </video>
   
      
      <div className="content-container z-10 flex flex-col items-center justify-center p-4 md:p-10 bg-opacity-80 bg-white rounded-lg shadow-xl">
         <h1 className="text-3xl md:text-4xl font-bold text-blue-900 mb-4">Greater Gotham Bank</h1>
             <img src="GGB.png" alt="Greater Gotham Bank Logo" className="logo w-40 md:w-56 mb-4"/>
        <h1 className="text-2xl md:text-1xl font-bold text-blue-900 mb-4">Empowering your financial freedom</h1>
    </div>

    </div>
  );
};

export default Host;
