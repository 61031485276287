// src/components/MovingGradientHero.tsx
import React from 'react';
import '../index.css';  // Ensure the CSS is imported

const MovingGradientHero: React.FC = () => {
  return (
    <div className="moving-gradient flex flex-col items-center justify-center text-center px-4 py-10">
      <img src="GGB.png" alt="Greater Gotham Bank Logo" className="logo w-40 md:w-56 mb-4"/>
      <h1 className="text-3xl md:text-4xl font-bold text-blue-900 mb-4">Greater Gotham Bank</h1>
      <h2 className="subtext mt-2 text-base md:text-lg text-blue-900">
        Millions of companies of all sizes use our services to manage their finances, process payments, and grow their businesses.
      </h2>
      <button className="button mt-4 bg-blue-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-blue-700">
        Start Now
      </button>
    </div>
  );
};

export default MovingGradientHero;
