import React from 'react';

const ServiceOptions = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-cover bg-center h-56 p-4" style={{ backgroundImage: "url('/assets/p2.jpg')" }}>
            <h2 className="text-4xl md:text-1xl font-bold text-blue-900 mb-4">Corporations & Institutions</h2>
          </div>
          <div className="px-4 py-2">
            <p className="text-black">
              We serve a broad range of companies, organizations, and institutions through our financing, investing, execution and advisory capabilities.
            </p>
            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Learn More</button>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-cover bg-center h-56 p-4" style={{ backgroundImage: "url('/assets/p1.jpg')" }}>
            <h2 className="text-4xl md:text-1xl font-bold text-blue-900 mb-4">Individuals</h2>
          </div>
          <div className="px-4 py-2">
            <p className="text-white">
              We provide insights, guidance, and services to help you achieve your financial and investing goals.
            </p>
            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOptions;
